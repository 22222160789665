@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  font-size: 16px;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  font-family: "Montserrat", "Roboto", sans-serif !important;
  font-weight: 500;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  overflow: hidden;
}

#root {
  display: flex;
  height: 100vh;
}

.routesContainer {
  width: 100%;
}

.hasHeader {
  margin-top: 70px;
  margin-left: 52px;
  transition: margin-left 195ms ease-in-out;
  width: calc(100% - 52px);
}

.hasHeader.open {
  margin-left: 260px;
  width: calc(100% - 260px);
}

.daterangepicker {
  font-family: inherit !important;
  font-weight: inherit;
}

.daterangepicker .yearselect,
.daterangepicker .monthselect {
  font-family: inherit;
  font-weight: inherit;
}

.gm-style-iw-chr {
  display: none;
}